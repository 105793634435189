import "./styles/Index.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Dashboard from "./Components/Views/Dashboard";
import ContextProvider from "./Components/Context/ContextProvider";
import Navbar from "./Components/Navbar";
import NoPage from "./Components/Views/NoPage";

const App = () => {
  return (
    <div className="App">
      <ContextProvider>
        <Router>
          <Navbar />
          <Switch>
            <Route exact path="/">
              <Dashboard />
            </Route>
            <Route path='*' exact={true} component={NoPage} />
          </Switch>
        </Router>
      </ContextProvider>
    </div>
  );
};

export default App;
