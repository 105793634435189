import "../../styles/Dashboard.scss";
import WeeklyHeatMapCard from "../Cards/WeeklyHeatMapCard";
import FloorplanCard from "../Cards/FloorplanCard";
import OccupancyCard from "../Cards/OccupancyCard";

const Dashboard = () => {
  return (
    <div className="dashboard-wrapper">
      <FloorplanCard />
      <WeeklyHeatMapCard />
      <OccupancyCard />
    </div>
  );
};

export default Dashboard;
