import "../../styles/Floorplan.scss";
import { useContext, useEffect, useRef, useState } from "react";
import { AnalyticsApi, ConfigurationApi } from "../../api/api";
import { Context } from "../Context/ContextProvider";
import CircleDrawer from "../CircleDrawer";
import Card from "./Card";

type Size = {
  width: number;
  height: number;
};

const FloorplanCard = () => {
  const [floorPlan, setFloorPlan] = useState<string>("1");
  const [circles] = useState<any>([
    { id: 1, name: "", xCoord: -100, yCoord: -100, percentageUsed: 0 },
  ]);
  const {
    selectedSpot,
    setSelectedSpot,
    selectedSpotName,
    boxStats,
    setBoxStats,
    startDateString,
    endDateString,
    userConfig,
  } = useContext(Context);
  const targetRef = useRef<any>();
  const [mapSize, setMapSize] = useState<Size>({ width: 1, height: 1 });

  // Fetches floorplan image
  useEffect(() => {
    if (userConfig != 0) {
      try {
        // console.log("userConfig", userConfig); // causes error for undefined value first render
        new ConfigurationApi()
          .getFloorplanImage(userConfig.floorplans[0].id) // add more floorplans in future?
          .then((response: any) => {
            setFloorPlan(response.request.responseURL);
          });
      } catch (error) {
        console.error(error);
      }
    }
  }, [userConfig]);

  // Scale map size on resize
  useEffect(() => {
    window.onresize = resizeHandler;
  }, []);

  // Resize interval
  useEffect(() => {
    const interval = setInterval(() => {
      resizeHandler();
    }, 200);
  }, []);

  // Handles the resize with refs on img-overlay-wrapper div
  const resizeHandler = () => {
    if (targetRef.current != null) {
      try {
        const width = targetRef.current.offsetWidth;
        const height = targetRef.current.offsetHeight;

        setMapSize({
          width: width,
          height: height,
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  // Fetches boxstats with floorplan id between two dates
  useEffect(() => {
    if (userConfig != 0) {
      try {
        new AnalyticsApi()
          .getFloorplanBoxStats(
            userConfig.floorplans[0].id,
            startDateString + "T00:01:50.666Z",
            endDateString + "T23:59:50.666Z"
          )
          .then((response: any) => {
            setBoxStats(response.data);
          });
      } catch (error) {
        console.error(error);
      }
    }
  }, [endDateString, userConfig]);

  // Creates circle objects to store id and percentage used for use in CircleDrawer
  useEffect(() => {
    if (userConfig != 0) {
      try {
        for (let i = 0; i < userConfig.floorplans[0].boxes.length; i++) {
          const { coords } = userConfig.floorplans[0].boxes[i];
          const id = userConfig.floorplans[0].boxes[i].id;
          const name = userConfig.floorplans[0].boxes[i].name;
          const percentageUsed = Math.round(boxStats[id] * 100);

          let xCoord =
            ((coords[0] + coords[2] + coords[4] + coords[6]) / 4) * mapSize.width;
          let yCoord =
            ((coords[1] + coords[3] + coords[5] + coords[7]) / 4) *
            mapSize.height;

          if (circles.length >= userConfig.floorplans[0].boxes.length) {
            circles.shift();
          }

          circles.push({ id, name, xCoord, yCoord, percentageUsed });
        }
      } catch (error) {
        console.error(error);
      }
    }
  }, [userConfig, mapSize]);

  const noSpot = () => {
    setSelectedSpot("0");
  };

  return (
    <Card>
      <h2 className="card-header">Heatmap for Floorplan: {userConfig.name}</h2>
      <div className="card-body">
        <div className="img-overlay-wrapper unselectable" ref={targetRef}>
          <CircleDrawer mapSize={mapSize} circles={circles} />
          <img className="floor-plan" src={floorPlan} alt="IMG" />
        </div>
        <div className="select-spot">
          {selectedSpot.length === 1 && <p>Select a spot on the map...</p>}
          {selectedSpot.length > 1 &&
          <div className="spot-info"><button className="selected" onClick={noSpot}><p>X</p> </button><p>{selectedSpotName}</p></div>
          }
        </div>

      </div>
    </Card>
  );
};

export default FloorplanCard;
