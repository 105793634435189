import { useContext, useEffect, useState } from "react";
import { Context } from "./Context/ContextProvider";

type circle = {
  id: string;
  name: string;
  xCoord: number;
  yCoord: number;
  percentageUsed: number;
};

const CircleDrawer = (props: {
  circles: [circle];
  mapSize: { width: number; height: number };
}) => {
  const {
    setSelectedSpot,
    setSelectedSpotName,
    heatmapData,
  } = useContext(Context);
  const [percentageArray, setPercentageArray] = useState<any>([0]);
  const [minMaxDiff, setMinMaxDiff] = useState<any>(0);

  const circleClick = (circle: circle) => {
    console.log(circle);
    setSelectedSpot(circle.id);
    setSelectedSpotName(circle.name);
  }

  // calculates difference between min and max values
  // bug sometimes gives NaN
  useEffect(() => {
    var arr = props.circles.map((circle: circle) => {
      return circle.percentageUsed;
    });
    setPercentageArray(arr);
    setMinMaxDiff(Math.max(...arr) - Math.min(...arr));
  }, [props.circles, heatmapData, props.mapSize]);

  const ratioCalc = (percentage: number) => 
  (percentage - Math.min(...percentageArray)) / minMaxDiff;

  // fill circle with color depending on ratio
  const calcFill = (_circle: circle) => {
    if (_circle.xCoord <= 2 || _circle.yCoord <= 2) {
      return `rgb(255, 255, 255, 0.1)`;
    } else {
      if (_circle.percentageUsed === 0) {
        return "rgb(108, 167, 255, 0.8)";
      } else {
        return `rgb(
          ${255 * ratioCalc(_circle.percentageUsed) + 50}, 
          ${255 * (1 - ratioCalc(_circle.percentageUsed)) + 70}, 
          0,  
          ${ratioCalc(_circle.percentageUsed) * 30}
          )`;
      }
    }
  }

  return (
    <svg id="svg-circles">
      <filter id="blurMe">
        <feGaussianBlur stdDeviation="0" />
      </filter>
      {props.circles.map((_circle: circle) => (
        <circle
          onClick={() => circleClick(_circle)}
          key={_circle.id}
          cx={_circle.xCoord}
          cy={_circle.yCoord}
          r={props.mapSize.width / 22}
          strokeWidth="3"
          fill={calcFill(_circle)}
          fillOpacity="0.7"
          filter="url(#blurMe)"
        >
          <title>
          {_circle.name} used {_circle.percentageUsed}% of the time
          </title>
          <text x="150%" y="150%" textAnchor="middle" stroke="#51c5cf" stroke-width="2px" dy=".3em">Testing stuff</text>
        </circle>
      ))}
    </svg>
  );
};

export default CircleDrawer;
