import { useContext, useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Context } from "../Context/ContextProvider";
import Card from "./Card";

const OccupancyCard = () => {
  const { chartData, selectedSpot, nrOfBoxes, nrOfWeeks } = useContext(Context);
  const [offSet, setOffSet] = useState(0);


  const occupancyData = chartData.reduce((acc: { [x: string]: any; }, curr: { time: string | number; percentage: any; }) => {
    if (selectedSpot === "0") {
      if (acc[curr.time]) {
        acc[curr.time] += curr.percentage / nrOfBoxes;
      } else {
        acc[curr.time] = curr.percentage;
      }
      return acc;
    } else {
      if (acc[curr.time]) {
        acc[curr.time] += curr.percentage;
      } else {
        acc[curr.time] = curr.percentage;
      }
      return acc;
    }

  }, {});

  // convert to array for recharts
  const arrayData = Object.keys(occupancyData).map((key) => {
    return {
      time: key,
      percentage: occupancyData[key],
    };
  });

  // determines x axis interval
  // needs to be dynamically changed based on number of spots
  // const xOffsetCalc = () => {
  //   console.log(arrayData.length);
  //   console.log("nrOfBoxes", nrOfBoxes);
  //   if (arrayData.length  / nrOfBoxes  <= 168) { // one week
  //    return 5;
  //   } if (arrayData.length  / nrOfBoxes  > 168 && arrayData.length < 336 ) { // two weeks
  //     return 11;
  //   } else {
  //     return 23;
  //   }
  // }

  // determines x axis interval depending on nr of weeks
  useEffect(() => {
    // console.log("arrayData.length", arrayData.length);
    // console.log("nrOfBoxes", nrOfBoxes);
    if (nrOfWeeks <= 1) {
      setOffSet(5);
      // console.log("offset 5")
    } else if (nrOfWeeks >= 1 && nrOfWeeks <= 3) {
      setOffSet(23);
      // console.log("offset 23")
    } else if (nrOfWeeks > 3) {
      setOffSet(47);
      // console.log("offset 47")
    }
  }, [chartData, nrOfBoxes])

  return (
    <Card>
      <h2 className="card-header">Hourly Occupancy</h2>
      <div className="card-body  unselectable">
        <ResponsiveContainer className="chart" height={250}>
          <LineChart
            className={"line-chart"}
            data={arrayData}
          >
            <CartesianGrid
              horizontal={true}
              vertical={true}
              strokeDasharray="2 2" 
              stroke={"rgba(94, 94, 94, 0.4)"}
              />
            <XAxis
              dataKey={"time"}
              tick={{
                fill: "black",
                fontSize: "0.8rem",
              }}
              stroke={"#9196a0"}
              angle={45}
              interval={offSet}
              height={70}
              dy={23}
              dx={23}
              />
            <YAxis
              dataKey={"percentage"}
              tick={{ 
                fill: "black",
                fontSize: "0.8rem" 
              }}
              stroke={"#9196a0"}
            />
            <Tooltip
              contentStyle={{ backgroundColor: "#3f4450", color: "#fff" }}
              itemStyle={{ color: "#fff" }}
              cursor={true}
            />
            <Legend verticalAlign="top" />
            <Line
              type={"monotone"}
              name={"Occupancy percentage"}
              animationDuration={500}
              dataKey={"percentage"}
              strokeWidth={"3"}
              dot={{ fill: "#b1c1e7", stroke: "#b1c1e7", strokeWidth: 2, r: 0 }}
              stroke={"#b1c1e7"}
              activeDot={{
                fill: "#b1c1e7",
                stroke: "#b1c1e7",
                strokeWidth: 2,
                r: 4,
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </Card>
  );
};

export default OccupancyCard;
