import { createContext, useEffect, useLayoutEffect, useState } from "react";
import { AnalyticsApi, ConfigurationApi } from "../../api";
import { format } from "date-fns";

export const Context = createContext<any>({});

const ContextProvider = (props: { children: any }) => {
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [startDateString, setStartDateString] = useState<string>();
  const [endDateString, setEndDateString] = useState<string>();
  const [userConfig, setUserConfig] = useState<any>([]);
  const [selectedSpot, setSelectedSpot] = useState<string>("0");
  const [selectedSpotName, setSelectedSpotName] = useState<string>("");
  const [boxStats, setBoxStats] = useState<any>([]);
  const [watchBoxStats, setWatchBoxStats] = useState<any>([]);
  const [chartData, setChartData] = useState<any>([]);
  const [formattedWatchboxData, setFormattedWatchboxData] = useState<any>([]);
  const [heatmapData, setheatmapData] = useState<any>([]);
  const [nrOfBoxes, setNrOfBoxes] = useState<number>(1);
  const [nrOfWeeks, setNrOfWeeks] = useState<number>(0);
  const userId = "demo@oniq.solutions";

  // Fetches userConfig from the API
  useLayoutEffect(() => {
    try {
      new ConfigurationApi()
        .getUserConfig(userId)
        .then((response: any) => {
          setUserConfig(response.data.zones[0]);
        });
    } catch (error) {
      console.error(error);
    }
  }, []);

  // Fetches userConfig from the API on user input
  useEffect(() => {
    try {
      new ConfigurationApi()
        .getUserConfig(userId)
        .then((response: any) => {
          setUserConfig(response.data.zones[0]);
        });
    } catch (error) {
      console.error(error);
    }
  }, [endDateString, selectedSpot]);

  // Fetches watchBoxStats with box id and dates
  useEffect(() => {
    if (userConfig != 0) {
      try {
        if (selectedSpot.length > 5) {
          new AnalyticsApi()
            .getWatchboxStats(
              selectedSpot,
              startDateString + "T00:00:10.000Z",
              endDateString + "T23:59:50.000Z"
            )
            .then((response: any) => {
              // console.log("ifSpotWatchBoxStats: ", response.data);
              setWatchBoxStats(response.data);
            });
        } else if (
          selectedSpot === "0" &&
          userConfig.floorplans[0].boxes.length > 3
        ) {
          var arr = [];
          for (let i = 0; i < userConfig.floorplans[0].boxes.length; i++) {
            arr.push(
              new AnalyticsApi()
                .getWatchboxStats(
                  userConfig.floorplans[0].boxes[i].id,
                  startDateString + "T00:00:10.000Z",
                  endDateString + "T23:59:50.000Z"
                )
                .then((response: any) => {
                  // console.log("ifNoSpotWatchBoxStats: ", response.data);
                  return response.data;
                })
            );
          }
          Promise.all(arr).then((all) => {
            setWatchBoxStats(all.flat());
          });
        }
        setNrOfBoxes(userConfig.floorplans[0].boxes.length);
      } catch (error) {
        console.error(error);
      }
    }
  }, [userConfig, endDateString, selectedSpot]);

  // Creates new data array from watchBoxStats for use in charts
  useEffect(() => {
    // if (nrOfWeeks <= 1) {
    setChartData(
      watchBoxStats.map((watchBoxStats: any) => {
        return {
          time: new Date(watchBoxStats.hour).toISOString().slice(5, 16).replace('T', ' '),
          percentage: Math.round(watchBoxStats.occupied_percentage),
        };
      })
    );
    // } else if (nrOfWeeks > 1) {
    //   setChartData(
    //     watchBoxStats.map((watchBoxStats: any) => {
    //       return {
    //         time: new Date(watchBoxStats.hour).toISOString().slice(5, 16).replace('T', ' '),
    //         percentage: Math.round(watchBoxStats.occupied_percentage),
    //       };
    //     })
    //     );
    // }
  }, [watchBoxStats]);

  // Creates formattedWatchboxData array from watchBoxStats
  useEffect(() => {
    // console.log(watchBoxStats);
    setFormattedWatchboxData(
      watchBoxStats.map((watchBoxStats: any) => {
        return {
          week: format(new Date(watchBoxStats.hour), "I"),
          day: format(new Date(watchBoxStats.hour), "i"),
          hour: format(new Date(watchBoxStats.hour), "HH"),
          percentage: Math.round(watchBoxStats.occupied_percentage),
        };
      })
    );
  }, [watchBoxStats, endDateString]);

  // Creates heatmapdata array from formattedWatchboxData
  useEffect(() => {
    if (userConfig != 0) {
      try {
        var arr = [];
        for (let i = 0; i < formattedWatchboxData.length; i++) {
          arr.push(formattedWatchboxData[i].day);
        }

        var days = [...new Set(arr)];
        var arr = [];
        var heatmapData = [];

        for (let i = 0; i < formattedWatchboxData.length; i++) {
          arr.push(formattedWatchboxData[i].hour);
        }

        var hours = [...new Set(arr)];

        if (selectedSpot === "0") {
          setNrOfWeeks(
            arr.length / userConfig.floorplans[0].boxes.length / 24 / 7)
        } else {
          setNrOfWeeks(arr.length / 24 / 7)
        }
        // console.log("nrOfWeeks: ", nrOfWeeks);

        for (let i = 0; i < hours.length; i++) {

          var arr = [];

          for (let j = 0; j < days.length; j++) {
            var percentage = 0;
            for (let k = 0; k < formattedWatchboxData.length; k++) {
              if (
                formattedWatchboxData[k].day === days[j] &&
                formattedWatchboxData[k].hour === hours[i]
              ) {
                percentage += formattedWatchboxData[k].percentage;
              }
            }
            if (selectedSpot === "0") {
              arr.push(
                Math.round(
                  percentage / userConfig.floorplans[0].boxes.length / nrOfWeeks
                )
              );
            } else {
              arr.push(Math.round(percentage / nrOfWeeks));
            }
          }
          heatmapData.push(arr);
        }
        setheatmapData(heatmapData.slice(6, 20));
      } catch (error) {
        console.error(error);
      }
    }
    // console.log("heatmapData: ", heatmapData);
  }, [formattedWatchboxData]);


  // if (userConfig != 0){
  //   console.log(userConfig.floorplans[0].id)
  // }

  // Values to be reachable from children props
  const values = {
    startDateString,
    setStartDateString,
    endDateString,
    setEndDateString,
    userConfig,
    selectedSpot,
    setSelectedSpot,
    setSelectedSpotName,
    selectedSpotName,
    boxStats,
    setBoxStats,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    watchBoxStats,
    setWatchBoxStats,
    chartData,
    heatmapData,
    nrOfBoxes,
    nrOfWeeks
  };

  return (
    <div>
      <Context.Provider value={values}>{props.children}</Context.Provider>
    </div>
  );
};

export default ContextProvider;
