import { useContext, useState } from "react";
import { Context } from "../Context/ContextProvider";
import Card from "./Card";
import { HeatMapGrid } from "react-grid-heatmap";

const WeeklyHeatMapCard = () => {
  const { heatmapData } = useContext(Context);
  const [xLabels] = useState(["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]);
  const [yLabels] = useState<any>([
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
  ]);

  // const calcWeekdays = () => {
  //   if (heatmapData.length[0] < 7) {
  //     return ["", "", "", "", "", "", ""];
  //   } else {
  //     return xLabels;
  //   }
  // }

  const cellRender = ( x: any, y: any, value: any) => {
    if (value && x  && y || value === 0 || x != NaN && y != NaN) {
      return (
        <div title={`${value}%`}>{value}</div>
      );
    }
  }

  return (
    <Card>
      <h2 className="card-header">Weekly Heatmap</h2>
      <div className="card-body">
        <div className="time-heatmap unselectable">
          <HeatMapGrid
            data={heatmapData}
            xLabels={xLabels}
            yLabels={yLabels}
            cellHeight={"1.5rem"}
            cellRender={(_x, _y, value) => <div title={`${value}%`}>{cellRender(_x, _y, value)}</div>}
            cellStyle={(_x, _y, ratio) => ({
              background: `rgb(
                ${255 * ratio + 50}, 
                ${255 * (1 - ratio) + 70}, 
                0,  
                ${ratio * 5})`,
              fontSize: "1rem",
              color: `rgb(0, 0, 0, ${ratio / 2 + 0.4})`,
            })}
            xLabelsStyle={() => ({
              fontSize: "1rem",
            })}
            yLabelsStyle={() => ({
              fontSize: "1rem",
            })}
          />
        </div>
      </div>
    </Card>
  );
};

export default WeeklyHeatMapCard;
