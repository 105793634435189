import "react-datepicker/dist/react-datepicker.css";
import { useEffect, useContext } from "react";
import DatePicker from "react-datepicker";
import { Context } from "./Context/ContextProvider";

const Options = () => {
  const {
    endDate,
    setEndDate,
    startDate,
    setStartDate,
    setStartDateString,
    setEndDateString,
  } = useContext(Context);

  // handles change of dates
  const onChange = (dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  // sets the start date
  useEffect(() => {
    var d = new Date();
    d.setDate(d.getDate() - (d.getDay() + 6) % 14);
    setStartDate(d);
  }, []);

  // sets the start date string
  useEffect(() => {
    let d = new Date(startDate),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    setStartDateString([year, month, day].join("-"));
  }, [ startDate ]);

  // sets the end date string
  useEffect(() => {
    let d = new Date(endDate),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    setEndDateString([year, month, day].join("-"));
  }, [ endDate ]);

  return (
    <div className="date-picker">
      <DatePicker
        dateFormat="yyy-MM-dd"
        selected={startDate}
        onChange={onChange}
        startDate={startDate}
        endDate={endDate}
        selectsRange
        maxDate={new Date()}
        showMonthDropdown
        monthsShown={1}
        calendarStartDay={1}
        showWeekNumbers
      />
    </div>
  );
};

export default Options;
