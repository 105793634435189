/* tslint:disable */
/* eslint-disable */
/**
 * ONQ-VISION
 * API for querying ONIQ-VISION statisics
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: dev@oniqvision.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Camera
 */
export interface Camera {
    /**
     * boxes to track for this camera
     * @type {Array<Watchbox>}
     * @memberof Camera
     */
    boxes?: Array<Watchbox>;
    /**
     * camera uuid
     * @type {string}
     * @memberof Camera
     */
    id: string;
    /**
     * user-provided camera name
     * @type {string}
     * @memberof Camera
     */
    name: string;
}
/**
 * 
 * @export
 * @interface CameraMapping
 */
export interface CameraMapping {
    /**
     * projection matrix
     * @type {Array<number>}
     * @memberof CameraMapping
     */
    projection?: Array<number>;
    /**
     * camera uuid
     * @type {string}
     * @memberof CameraMapping
     */
    uuid?: string;
}
/**
 * 
 * @export
 * @interface Credentials
 */
export interface Credentials {
    /**
     * user password (plaintext)
     * @type {string}
     * @memberof Credentials
     */
    password: string;
    /**
     * user login name
     * @type {string}
     * @memberof Credentials
     */
    username: string;
}
/**
 * 
 * @export
 * @interface DaySummary
 */
export interface DaySummary {
    /**
     * 
     * @type {number}
     * @memberof DaySummary
     */
    count: number;
    /**
     * 
     * @type {string}
     * @memberof DaySummary
     */
    date: string;
}
/**
 * 
 * @export
 * @interface DemographicsBucket
 */
export interface DemographicsBucket {
    /**
     * Age group
     * @type {string}
     * @memberof DemographicsBucket
     */
    ageGroup?: string;
    /**
     * 
     * @type {string}
     * @memberof DemographicsBucket
     */
    gender?: string;
    /**
     * value
     * @type {number}
     * @memberof DemographicsBucket
     */
    value?: number;
}
/**
 * 
 * @export
 * @interface Detection
 */
export interface Detection {
    /**
     * 
     * @type {number}
     * @memberof Detection
     */
    radius?: number;
    /**
     * 
     * @type {string}
     * @memberof Detection
     */
    time?: string;
    /**
     * 
     * @type {number}
     * @memberof Detection
     */
    x?: number;
    /**
     * 
     * @type {number}
     * @memberof Detection
     */
    y?: number;
}
/**
 * 
 * @export
 * @interface Floorplan
 */
export interface Floorplan {
    /**
     * boxes to track for this floorplan
     * @type {Array<Watchbox>}
     * @memberof Floorplan
     */
    boxes?: Array<Watchbox>;
    /**
     * associated cameras
     * @type {Array<Camera>}
     * @memberof Floorplan
     */
    cameras?: Array<Camera>;
    /**
     * floorplan id
     * @type {string}
     * @memberof Floorplan
     */
    id: string;
    /**
     * floorplan name
     * @type {string}
     * @memberof Floorplan
     */
    name: string;
}
/**
 * 
 * @export
 * @interface HeatMapBucket
 */
export interface HeatMapBucket {
    /**
     * 
     * @type {number}
     * @memberof HeatMapBucket
     */
    radius?: number;
    /**
     * 
     * @type {number}
     * @memberof HeatMapBucket
     */
    value?: number;
    /**
     * 
     * @type {number}
     * @memberof HeatMapBucket
     */
    x_bin?: number;
    /**
     * 
     * @type {number}
     * @memberof HeatMapBucket
     */
    y_bin?: number;
}
/**
 * 
 * @export
 * @interface HeatmapTable
 */
export interface HeatmapTable {
    /**
     * 
     * @type {Array<HeatmapTableData>}
     * @memberof HeatmapTable
     */
    buckets?: Array<HeatmapTableData>;
}
/**
 * 
 * @export
 * @interface HeatmapTableData
 */
export interface HeatmapTableData {
    /**
     * bucketed time
     * @type {string}
     * @memberof HeatmapTableData
     */
    time: string;
    /**
     * number of heatmap detections during this bucket
     * @type {number}
     * @memberof HeatmapTableData
     */
    value: number;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * error code
     * @type {string}
     * @memberof ModelError
     */
    code?: string;
    /**
     * possible error details
     * @type {string}
     * @memberof ModelError
     */
    details?: string;
    /**
     * possible error hint
     * @type {string}
     * @memberof ModelError
     */
    hint?: string;
    /**
     * message describing error code
     * @type {string}
     * @memberof ModelError
     */
    message?: string;
}
/**
 * 
 * @export
 * @interface StatSummary
 */
export interface StatSummary {
    /**
     * 
     * @type {Array<DaySummary>}
     * @memberof StatSummary
     */
    visits?: Array<DaySummary>;
}
/**
 * 
 * @export
 * @interface TimeBucket
 */
export interface TimeBucket {
    /**
     * 
     * @type {string}
     * @memberof TimeBucket
     */
    time: string;
    /**
     * 
     * @type {number}
     * @memberof TimeBucket
     */
    value: number;
}
/**
 * 
 * @export
 * @interface TimeBuckets
 */
export interface TimeBuckets {
    /**
     * 
     * @type {Array<TimeBucket>}
     * @memberof TimeBuckets
     */
    buckets?: Array<TimeBucket>;
    /**
     * time inteval for each bucket ie 1d, 1h 5m etc
     * @type {string}
     * @memberof TimeBuckets
     */
    interval?: string;
}
/**
 * 
 * @export
 * @interface Watchbox
 */
export interface Watchbox {
    /**
     * box coordinates in [topleftx, toplefty, bottomrightx, bottomrighty] (aka. [minx, miny, maxx, maxy])
     * @type {Array<number>}
     * @memberof Watchbox
     */
    coords: Array<number>;
    /**
     * box id, use same id for cameras and floorplans you wish to map to this id
     * @type {string}
     * @memberof Watchbox
     */
    id: string;
    /**
     * box name
     * @type {string}
     * @memberof Watchbox
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface WatchboxBucket
 */
export interface WatchboxBucket {
    /**
     * hourly time bucket
     * @type {string}
     * @memberof WatchboxBucket
     */
    hour: string;
    /**
     * occupancy % in bucket
     * @type {number}
     * @memberof WatchboxBucket
     */
    occupied_percentage: number;
}
/**
 * 
 * @export
 * @interface ZoneConfig
 */
export interface ZoneConfig {
    /**
     * floorplans related to this zone
     * @type {Array<Floorplan>}
     * @memberof ZoneConfig
     */
    floorplans?: Array<Floorplan>;
    /**
     * unique zone id
     * @type {string}
     * @memberof ZoneConfig
     */
    id: string;
    /**
     * user-specified zone name
     * @type {string}
     * @memberof ZoneConfig
     */
    name: string;
    /**
     * user id of zone owner
     * @type {string}
     * @memberof ZoneConfig
     */
    owner: string;
}
/**
 * 
 * @export
 * @interface ZoneList
 */
export interface ZoneList {
    /**
     * 
     * @type {Array<ZoneConfig>}
     * @memberof ZoneList
     */
    zones?: Array<ZoneConfig>;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * authenticate and acquire an access token
         * @param {Credentials} [credentials] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessToken: async (credentials?: Credentials, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/auth`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(credentials, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * authenticate and acquire an access token
         * @param {Credentials} [credentials] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccessToken(credentials?: Credentials, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccessToken(credentials, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * authenticate and acquire an access token
         * @param {Credentials} [credentials] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccessToken(credentials?: Credentials, options?: any): AxiosPromise<void> {
            return localVarFp.getAccessToken(credentials, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * authenticate and acquire an access token
     * @param {Credentials} [credentials] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getAccessToken(credentials?: Credentials, options?: any) {
        return AccountApiFp(this.configuration).getAccessToken(credentials, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * AnalyticsApi - axios parameter creator
 * @export
 */
export const AnalyticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * query demograpics result
         * @param {string} cameraId 
         * @param {string} [rdate] A relative date range for the report, such as &#x60;Today&#x60; or &#x60;LastWeek&#x60;. For an exact range, use &#x60;start_date&#x60; and &#x60;end_date&#x60; instead. 
         * @param {string} [startDate] The start date for the report. Must be used together with &#x60;end_date&#x60;. This parameter is incompatible with &#x60;rdate&#x60;. 
         * @param {string} [endDate] The end date for the report. Must be used together with &#x60;start_date&#x60;. This parameter is incompatible with &#x60;rdate&#x60;. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demographics: async (cameraId: string, rdate?: string, startDate?: string, endDate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('demographics', 'cameraId', cameraId)
            const localVarPath = `/{cameraId}/demographics`
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (rdate !== undefined) {
                localVarQueryParameter['rdate'] = rdate;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get summarized box stats for this floorplan
         * @param {string} planId unique floorplan id (uuid)
         * @param {string} startDate the start date
         * @param {string} endDate the end date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFloorplanBoxStats: async (planId: string, startDate: string, endDate: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'planId' is not null or undefined
            assertParamExists('getFloorplanBoxStats', 'planId', planId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getFloorplanBoxStats', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getFloorplanBoxStats', 'endDate', endDate)
            const localVarPath = `/floorplan/{planId}/boxstats`
                .replace(`{${"planId"}}`, encodeURIComponent(String(planId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get heatmap data for camera
         * @param {string} cameraId 
         * @param {string} [rdate] A relative date range for the report, such as &#x60;Today&#x60; or &#x60;LastWeek&#x60;. For an exact range, use &#x60;start_date&#x60; and &#x60;end_date&#x60; instead. 
         * @param {string} [startDate] The start date for the report. Must be used together with &#x60;end_date&#x60;. This parameter is incompatible with &#x60;rdate&#x60;. 
         * @param {string} [endDate] The end date for the report. Must be used together with &#x60;start_date&#x60;. This parameter is incompatible with &#x60;rdate&#x60;. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHeatmapData: async (cameraId: string, rdate?: string, startDate?: string, endDate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('getHeatmapData', 'cameraId', cameraId)
            const localVarPath = `/heatmap/{cameraId}/data`
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (rdate !== undefined) {
                localVarQueryParameter['rdate'] = rdate;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get week report with binned heatmap data for a specific camera
         * @param {string} cameraId 
         * @param {string} [interval] time inteval for each bucket (1d, 1h, 5m, ...)
         * @param {string} [startDate] The start date for the data range. Must be used together with &#x60;end_date&#x60;. 
         * @param {string} [endDate] The end date for the data range. Must be used together with &#x60;start_date&#x60;. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHeatmapTable: async (cameraId: string, interval?: string, startDate?: string, endDate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('getHeatmapTable', 'cameraId', cameraId)
            const localVarPath = `/heatmap/{cameraId}/table`
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get \"raw\" object detection output for people
         * @param {string} cameraId 
         * @param {string} [startDate] The start date for the report. Must be used together with &#x60;end_date&#x60;. 
         * @param {string} [endDate] The end date for the report. Must be used together with &#x60;start_date&#x60;. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObjectDetections: async (cameraId: string, startDate?: string, endDate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('getObjectDetections', 'cameraId', cameraId)
            const localVarPath = `/detection/{cameraId}/people`
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * query hourly occupancy statistics for this watchbox
         * @param {string} boxId unique box id (uuid)
         * @param {string} startDate the start date
         * @param {string} endDate the end date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWatchboxStats: async (boxId: string, startDate: string, endDate: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'boxId' is not null or undefined
            assertParamExists('getWatchboxStats', 'boxId', boxId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('getWatchboxStats', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('getWatchboxStats', 'endDate', endDate)
            const localVarPath = `/watchbox/{boxId}/stats`
                .replace(`{${"boxId"}}`, encodeURIComponent(String(boxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * query statistics summary
         * @param {string} cameraId 
         * @param {string} [startDate] The start date for the report. Must be used together with &#x60;end_date&#x60;. 
         * @param {string} [endDate] The end date for the report. Must be used together with &#x60;start_date&#x60;. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        summary: async (cameraId: string, startDate?: string, endDate?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('summary', 'cameraId', cameraId)
            const localVarPath = `/{cameraId}/summary`
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Query count over time
         * @param {string} cameraId 
         * @param {string} [rdate] A relative date range for the report, such as &#x60;Today&#x60; or &#x60;LastWeek&#x60;. For an exact range, use &#x60;start_date&#x60; and &#x60;end_date&#x60; instead. 
         * @param {string} [startDate] The start date for the report. Must be used together with &#x60;end_date&#x60;. This parameter is incompatible with &#x60;rdate&#x60;. 
         * @param {string} [endDate] The end date for the report. Must be used together with &#x60;start_date&#x60;. This parameter is incompatible with &#x60;rdate&#x60;. 
         * @param {string} [interval] time inteval for each bucket ie 1d, 1h 5m etc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timeseries: async (cameraId: string, rdate?: string, startDate?: string, endDate?: string, interval?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('timeseries', 'cameraId', cameraId)
            const localVarPath = `/{cameraId}/timeseries`
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (rdate !== undefined) {
                localVarQueryParameter['rdate'] = rdate;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString() :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString() :
                    endDate;
            }

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnalyticsApi - functional programming interface
 * @export
 */
export const AnalyticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnalyticsApiAxiosParamCreator(configuration)
    return {
        /**
         * query demograpics result
         * @param {string} cameraId 
         * @param {string} [rdate] A relative date range for the report, such as &#x60;Today&#x60; or &#x60;LastWeek&#x60;. For an exact range, use &#x60;start_date&#x60; and &#x60;end_date&#x60; instead. 
         * @param {string} [startDate] The start date for the report. Must be used together with &#x60;end_date&#x60;. This parameter is incompatible with &#x60;rdate&#x60;. 
         * @param {string} [endDate] The end date for the report. Must be used together with &#x60;start_date&#x60;. This parameter is incompatible with &#x60;rdate&#x60;. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async demographics(cameraId: string, rdate?: string, startDate?: string, endDate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DemographicsBucket>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.demographics(cameraId, rdate, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get summarized box stats for this floorplan
         * @param {string} planId unique floorplan id (uuid)
         * @param {string} startDate the start date
         * @param {string} endDate the end date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFloorplanBoxStats(planId: string, startDate: string, endDate: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFloorplanBoxStats(planId, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get heatmap data for camera
         * @param {string} cameraId 
         * @param {string} [rdate] A relative date range for the report, such as &#x60;Today&#x60; or &#x60;LastWeek&#x60;. For an exact range, use &#x60;start_date&#x60; and &#x60;end_date&#x60; instead. 
         * @param {string} [startDate] The start date for the report. Must be used together with &#x60;end_date&#x60;. This parameter is incompatible with &#x60;rdate&#x60;. 
         * @param {string} [endDate] The end date for the report. Must be used together with &#x60;start_date&#x60;. This parameter is incompatible with &#x60;rdate&#x60;. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHeatmapData(cameraId: string, rdate?: string, startDate?: string, endDate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HeatMapBucket>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHeatmapData(cameraId, rdate, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get week report with binned heatmap data for a specific camera
         * @param {string} cameraId 
         * @param {string} [interval] time inteval for each bucket (1d, 1h, 5m, ...)
         * @param {string} [startDate] The start date for the data range. Must be used together with &#x60;end_date&#x60;. 
         * @param {string} [endDate] The end date for the data range. Must be used together with &#x60;start_date&#x60;. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHeatmapTable(cameraId: string, interval?: string, startDate?: string, endDate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HeatmapTable>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHeatmapTable(cameraId, interval, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get \"raw\" object detection output for people
         * @param {string} cameraId 
         * @param {string} [startDate] The start date for the report. Must be used together with &#x60;end_date&#x60;. 
         * @param {string} [endDate] The end date for the report. Must be used together with &#x60;start_date&#x60;. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getObjectDetections(cameraId: string, startDate?: string, endDate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Detection>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getObjectDetections(cameraId, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * query hourly occupancy statistics for this watchbox
         * @param {string} boxId unique box id (uuid)
         * @param {string} startDate the start date
         * @param {string} endDate the end date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getWatchboxStats(boxId: string, startDate: string, endDate: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WatchboxBucket>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getWatchboxStats(boxId, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * query statistics summary
         * @param {string} cameraId 
         * @param {string} [startDate] The start date for the report. Must be used together with &#x60;end_date&#x60;. 
         * @param {string} [endDate] The end date for the report. Must be used together with &#x60;start_date&#x60;. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async summary(cameraId: string, startDate?: string, endDate?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.summary(cameraId, startDate, endDate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Query count over time
         * @param {string} cameraId 
         * @param {string} [rdate] A relative date range for the report, such as &#x60;Today&#x60; or &#x60;LastWeek&#x60;. For an exact range, use &#x60;start_date&#x60; and &#x60;end_date&#x60; instead. 
         * @param {string} [startDate] The start date for the report. Must be used together with &#x60;end_date&#x60;. This parameter is incompatible with &#x60;rdate&#x60;. 
         * @param {string} [endDate] The end date for the report. Must be used together with &#x60;start_date&#x60;. This parameter is incompatible with &#x60;rdate&#x60;. 
         * @param {string} [interval] time inteval for each bucket ie 1d, 1h 5m etc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async timeseries(cameraId: string, rdate?: string, startDate?: string, endDate?: string, interval?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimeBuckets>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.timeseries(cameraId, rdate, startDate, endDate, interval, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AnalyticsApi - factory interface
 * @export
 */
export const AnalyticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnalyticsApiFp(configuration)
    return {
        /**
         * query demograpics result
         * @param {string} cameraId 
         * @param {string} [rdate] A relative date range for the report, such as &#x60;Today&#x60; or &#x60;LastWeek&#x60;. For an exact range, use &#x60;start_date&#x60; and &#x60;end_date&#x60; instead. 
         * @param {string} [startDate] The start date for the report. Must be used together with &#x60;end_date&#x60;. This parameter is incompatible with &#x60;rdate&#x60;. 
         * @param {string} [endDate] The end date for the report. Must be used together with &#x60;start_date&#x60;. This parameter is incompatible with &#x60;rdate&#x60;. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        demographics(cameraId: string, rdate?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<Array<DemographicsBucket>> {
            return localVarFp.demographics(cameraId, rdate, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * get summarized box stats for this floorplan
         * @param {string} planId unique floorplan id (uuid)
         * @param {string} startDate the start date
         * @param {string} endDate the end date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFloorplanBoxStats(planId: string, startDate: string, endDate: string, options?: any): AxiosPromise<{ [key: string]: number; }> {
            return localVarFp.getFloorplanBoxStats(planId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * get heatmap data for camera
         * @param {string} cameraId 
         * @param {string} [rdate] A relative date range for the report, such as &#x60;Today&#x60; or &#x60;LastWeek&#x60;. For an exact range, use &#x60;start_date&#x60; and &#x60;end_date&#x60; instead. 
         * @param {string} [startDate] The start date for the report. Must be used together with &#x60;end_date&#x60;. This parameter is incompatible with &#x60;rdate&#x60;. 
         * @param {string} [endDate] The end date for the report. Must be used together with &#x60;start_date&#x60;. This parameter is incompatible with &#x60;rdate&#x60;. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHeatmapData(cameraId: string, rdate?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<Array<HeatMapBucket>> {
            return localVarFp.getHeatmapData(cameraId, rdate, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * get week report with binned heatmap data for a specific camera
         * @param {string} cameraId 
         * @param {string} [interval] time inteval for each bucket (1d, 1h, 5m, ...)
         * @param {string} [startDate] The start date for the data range. Must be used together with &#x60;end_date&#x60;. 
         * @param {string} [endDate] The end date for the data range. Must be used together with &#x60;start_date&#x60;. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHeatmapTable(cameraId: string, interval?: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<HeatmapTable> {
            return localVarFp.getHeatmapTable(cameraId, interval, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * get \"raw\" object detection output for people
         * @param {string} cameraId 
         * @param {string} [startDate] The start date for the report. Must be used together with &#x60;end_date&#x60;. 
         * @param {string} [endDate] The end date for the report. Must be used together with &#x60;start_date&#x60;. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObjectDetections(cameraId: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<Array<Detection>> {
            return localVarFp.getObjectDetections(cameraId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * query hourly occupancy statistics for this watchbox
         * @param {string} boxId unique box id (uuid)
         * @param {string} startDate the start date
         * @param {string} endDate the end date
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWatchboxStats(boxId: string, startDate: string, endDate: string, options?: any): AxiosPromise<Array<WatchboxBucket>> {
            return localVarFp.getWatchboxStats(boxId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * query statistics summary
         * @param {string} cameraId 
         * @param {string} [startDate] The start date for the report. Must be used together with &#x60;end_date&#x60;. 
         * @param {string} [endDate] The end date for the report. Must be used together with &#x60;start_date&#x60;. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        summary(cameraId: string, startDate?: string, endDate?: string, options?: any): AxiosPromise<StatSummary> {
            return localVarFp.summary(cameraId, startDate, endDate, options).then((request) => request(axios, basePath));
        },
        /**
         * Query count over time
         * @param {string} cameraId 
         * @param {string} [rdate] A relative date range for the report, such as &#x60;Today&#x60; or &#x60;LastWeek&#x60;. For an exact range, use &#x60;start_date&#x60; and &#x60;end_date&#x60; instead. 
         * @param {string} [startDate] The start date for the report. Must be used together with &#x60;end_date&#x60;. This parameter is incompatible with &#x60;rdate&#x60;. 
         * @param {string} [endDate] The end date for the report. Must be used together with &#x60;start_date&#x60;. This parameter is incompatible with &#x60;rdate&#x60;. 
         * @param {string} [interval] time inteval for each bucket ie 1d, 1h 5m etc
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        timeseries(cameraId: string, rdate?: string, startDate?: string, endDate?: string, interval?: string, options?: any): AxiosPromise<TimeBuckets> {
            return localVarFp.timeseries(cameraId, rdate, startDate, endDate, interval, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnalyticsApi - object-oriented interface
 * @export
 * @class AnalyticsApi
 * @extends {BaseAPI}
 */
export class AnalyticsApi extends BaseAPI {
    /**
     * query demograpics result
     * @param {string} cameraId 
     * @param {string} [rdate] A relative date range for the report, such as &#x60;Today&#x60; or &#x60;LastWeek&#x60;. For an exact range, use &#x60;start_date&#x60; and &#x60;end_date&#x60; instead. 
     * @param {string} [startDate] The start date for the report. Must be used together with &#x60;end_date&#x60;. This parameter is incompatible with &#x60;rdate&#x60;. 
     * @param {string} [endDate] The end date for the report. Must be used together with &#x60;start_date&#x60;. This parameter is incompatible with &#x60;rdate&#x60;. 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public demographics(cameraId: string, rdate?: string, startDate?: string, endDate?: string, options?: any) {
        return AnalyticsApiFp(this.configuration).demographics(cameraId, rdate, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get summarized box stats for this floorplan
     * @param {string} planId unique floorplan id (uuid)
     * @param {string} startDate the start date
     * @param {string} endDate the end date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getFloorplanBoxStats(planId: string, startDate: string, endDate: string, options?: any) {
        return AnalyticsApiFp(this.configuration).getFloorplanBoxStats(planId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get heatmap data for camera
     * @param {string} cameraId 
     * @param {string} [rdate] A relative date range for the report, such as &#x60;Today&#x60; or &#x60;LastWeek&#x60;. For an exact range, use &#x60;start_date&#x60; and &#x60;end_date&#x60; instead. 
     * @param {string} [startDate] The start date for the report. Must be used together with &#x60;end_date&#x60;. This parameter is incompatible with &#x60;rdate&#x60;. 
     * @param {string} [endDate] The end date for the report. Must be used together with &#x60;start_date&#x60;. This parameter is incompatible with &#x60;rdate&#x60;. 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getHeatmapData(cameraId: string, rdate?: string, startDate?: string, endDate?: string, options?: any) {
        return AnalyticsApiFp(this.configuration).getHeatmapData(cameraId, rdate, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get week report with binned heatmap data for a specific camera
     * @param {string} cameraId 
     * @param {string} [interval] time inteval for each bucket (1d, 1h, 5m, ...)
     * @param {string} [startDate] The start date for the data range. Must be used together with &#x60;end_date&#x60;. 
     * @param {string} [endDate] The end date for the data range. Must be used together with &#x60;start_date&#x60;. 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getHeatmapTable(cameraId: string, interval?: string, startDate?: string, endDate?: string, options?: any) {
        return AnalyticsApiFp(this.configuration).getHeatmapTable(cameraId, interval, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get \"raw\" object detection output for people
     * @param {string} cameraId 
     * @param {string} [startDate] The start date for the report. Must be used together with &#x60;end_date&#x60;. 
     * @param {string} [endDate] The end date for the report. Must be used together with &#x60;start_date&#x60;. 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getObjectDetections(cameraId: string, startDate?: string, endDate?: string, options?: any) {
        return AnalyticsApiFp(this.configuration).getObjectDetections(cameraId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * query hourly occupancy statistics for this watchbox
     * @param {string} boxId unique box id (uuid)
     * @param {string} startDate the start date
     * @param {string} endDate the end date
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public getWatchboxStats(boxId: string, startDate: string, endDate: string, options?: any) {
        return AnalyticsApiFp(this.configuration).getWatchboxStats(boxId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * query statistics summary
     * @param {string} cameraId 
     * @param {string} [startDate] The start date for the report. Must be used together with &#x60;end_date&#x60;. 
     * @param {string} [endDate] The end date for the report. Must be used together with &#x60;start_date&#x60;. 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public summary(cameraId: string, startDate?: string, endDate?: string, options?: any) {
        return AnalyticsApiFp(this.configuration).summary(cameraId, startDate, endDate, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Query count over time
     * @param {string} cameraId 
     * @param {string} [rdate] A relative date range for the report, such as &#x60;Today&#x60; or &#x60;LastWeek&#x60;. For an exact range, use &#x60;start_date&#x60; and &#x60;end_date&#x60; instead. 
     * @param {string} [startDate] The start date for the report. Must be used together with &#x60;end_date&#x60;. This parameter is incompatible with &#x60;rdate&#x60;. 
     * @param {string} [endDate] The end date for the report. Must be used together with &#x60;start_date&#x60;. This parameter is incompatible with &#x60;rdate&#x60;. 
     * @param {string} [interval] time inteval for each bucket ie 1d, 1h 5m etc
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnalyticsApi
     */
    public timeseries(cameraId: string, rdate?: string, startDate?: string, endDate?: string, interval?: string, options?: any) {
        return AnalyticsApiFp(this.configuration).timeseries(cameraId, rdate, startDate, endDate, interval, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConfigurationApi - axios parameter creator
 * @export
 */
export const ConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get camera configuration
         * @param {string} cameraId unique camera id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraConfig: async (cameraId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('getCameraConfig', 'cameraId', cameraId)
            const localVarPath = `/camera/{cameraId}`
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get floorplan image
         * @param {string} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFloorplanImage: async (planId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'planId' is not null or undefined
            assertParamExists('getFloorplanImage', 'planId', planId)
            const localVarPath = `/floorplan/{planId}/image`
                .replace(`{${"planId"}}`, encodeURIComponent(String(planId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get heatmap background image for camera
         * @param {string} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHeatmapImage: async (cameraId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'cameraId' is not null or undefined
            assertParamExists('getHeatmapImage', 'cameraId', cameraId)
            const localVarPath = `/heatmap/{cameraId}/image`
                .replace(`{${"cameraId"}}`, encodeURIComponent(String(cameraId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get ID of zones beloning to this user. No security, yay!
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserConfig: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserConfig', 'userId', userId)
            const localVarPath = `/user/{userId}/zones`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get zone config and associated floorplan, camera and watchbox ids
         * @param {string} zoneId unique zone id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZoneConfig: async (zoneId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'zoneId' is not null or undefined
            assertParamExists('getZoneConfig', 'zoneId', zoneId)
            const localVarPath = `/zone/{zoneId}`
                .replace(`{${"zoneId"}}`, encodeURIComponent(String(zoneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * remove a watchbox
         * @param {string} boxId unique box id (uuid)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWatchbox: async (boxId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'boxId' is not null or undefined
            assertParamExists('removeWatchbox', 'boxId', boxId)
            const localVarPath = `/watchbox/{boxId}`
                .replace(`{${"boxId"}}`, encodeURIComponent(String(boxId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * remove this zone and all related floorplan, cameras and watchboxes
         * @param {string} zoneId unique zone id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeZone: async (zoneId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'zoneId' is not null or undefined
            assertParamExists('removeZone', 'zoneId', zoneId)
            const localVarPath = `/zone/{zoneId}`
                .replace(`{${"zoneId"}}`, encodeURIComponent(String(zoneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update floorplan image
         * @param {string} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFloorplanImage: async (planId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'planId' is not null or undefined
            assertParamExists('updateFloorplanImage', 'planId', planId)
            const localVarPath = `/floorplan/{planId}/image`
                .replace(`{${"planId"}}`, encodeURIComponent(String(planId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update zone configuration declaratively. This operation will create, update and delete resources as necessary. 
         * @param {string} zoneId unique zone id
         * @param {ZoneConfig} config zone configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateZone: async (zoneId: string, config: ZoneConfig, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'zoneId' is not null or undefined
            assertParamExists('updateZone', 'zoneId', zoneId)
            // verify required parameter 'config' is not null or undefined
            assertParamExists('updateZone', 'config', config)
            const localVarPath = `/zone/{zoneId}`
                .replace(`{${"zoneId"}}`, encodeURIComponent(String(zoneId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(config, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConfigurationApi - functional programming interface
 * @export
 */
export const ConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * get camera configuration
         * @param {string} cameraId unique camera id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCameraConfig(cameraId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCameraConfig(cameraId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get floorplan image
         * @param {string} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFloorplanImage(planId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFloorplanImage(planId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get heatmap background image for camera
         * @param {string} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHeatmapImage(cameraId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHeatmapImage(cameraId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get ID of zones beloning to this user. No security, yay!
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserConfig(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserConfig(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * get zone config and associated floorplan, camera and watchbox ids
         * @param {string} zoneId unique zone id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getZoneConfig(zoneId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ZoneConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getZoneConfig(zoneId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * remove a watchbox
         * @param {string} boxId unique box id (uuid)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeWatchbox(boxId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeWatchbox(boxId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * remove this zone and all related floorplan, cameras and watchboxes
         * @param {string} zoneId unique zone id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeZone(zoneId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeZone(zoneId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * update floorplan image
         * @param {string} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFloorplanImage(planId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFloorplanImage(planId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update zone configuration declaratively. This operation will create, update and delete resources as necessary. 
         * @param {string} zoneId unique zone id
         * @param {ZoneConfig} config zone configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateZone(zoneId: string, config: ZoneConfig, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateZone(zoneId, config, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConfigurationApi - factory interface
 * @export
 */
export const ConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConfigurationApiFp(configuration)
    return {
        /**
         * get camera configuration
         * @param {string} cameraId unique camera id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCameraConfig(cameraId: string, options?: any): AxiosPromise<void> {
            return localVarFp.getCameraConfig(cameraId, options).then((request) => request(axios, basePath));
        },
        /**
         * get floorplan image
         * @param {string} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFloorplanImage(planId: string, options?: any): AxiosPromise<any> {
            return localVarFp.getFloorplanImage(planId, options).then((request) => request(axios, basePath));
        },
        /**
         * get heatmap background image for camera
         * @param {string} cameraId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHeatmapImage(cameraId: string, options?: any): AxiosPromise<any> {
            return localVarFp.getHeatmapImage(cameraId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get ID of zones beloning to this user. No security, yay!
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserConfig(userId: string, options?: any): AxiosPromise<ZoneList> {
            return localVarFp.getUserConfig(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * get zone config and associated floorplan, camera and watchbox ids
         * @param {string} zoneId unique zone id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getZoneConfig(zoneId: string, options?: any): AxiosPromise<ZoneConfig> {
            return localVarFp.getZoneConfig(zoneId, options).then((request) => request(axios, basePath));
        },
        /**
         * remove a watchbox
         * @param {string} boxId unique box id (uuid)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeWatchbox(boxId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeWatchbox(boxId, options).then((request) => request(axios, basePath));
        },
        /**
         * remove this zone and all related floorplan, cameras and watchboxes
         * @param {string} zoneId unique zone id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeZone(zoneId: string, options?: any): AxiosPromise<void> {
            return localVarFp.removeZone(zoneId, options).then((request) => request(axios, basePath));
        },
        /**
         * update floorplan image
         * @param {string} planId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFloorplanImage(planId: string, options?: any): AxiosPromise<void> {
            return localVarFp.updateFloorplanImage(planId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update zone configuration declaratively. This operation will create, update and delete resources as necessary. 
         * @param {string} zoneId unique zone id
         * @param {ZoneConfig} config zone configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateZone(zoneId: string, config: ZoneConfig, options?: any): AxiosPromise<void> {
            return localVarFp.updateZone(zoneId, config, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConfigurationApi - object-oriented interface
 * @export
 * @class ConfigurationApi
 * @extends {BaseAPI}
 */
export class ConfigurationApi extends BaseAPI {
    /**
     * get camera configuration
     * @param {string} cameraId unique camera id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public getCameraConfig(cameraId: string, options?: any) {
        return ConfigurationApiFp(this.configuration).getCameraConfig(cameraId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get floorplan image
     * @param {string} planId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public getFloorplanImage(planId: string, options?: any) {
        return ConfigurationApiFp(this.configuration).getFloorplanImage(planId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get heatmap background image for camera
     * @param {string} cameraId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public getHeatmapImage(cameraId: string, options?: any) {
        return ConfigurationApiFp(this.configuration).getHeatmapImage(cameraId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get ID of zones beloning to this user. No security, yay!
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public getUserConfig(userId: string, options?: any) {
        return ConfigurationApiFp(this.configuration).getUserConfig(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * get zone config and associated floorplan, camera and watchbox ids
     * @param {string} zoneId unique zone id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public getZoneConfig(zoneId: string, options?: any) {
        return ConfigurationApiFp(this.configuration).getZoneConfig(zoneId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * remove a watchbox
     * @param {string} boxId unique box id (uuid)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public removeWatchbox(boxId: string, options?: any) {
        return ConfigurationApiFp(this.configuration).removeWatchbox(boxId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * remove this zone and all related floorplan, cameras and watchboxes
     * @param {string} zoneId unique zone id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public removeZone(zoneId: string, options?: any) {
        return ConfigurationApiFp(this.configuration).removeZone(zoneId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * update floorplan image
     * @param {string} planId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public updateFloorplanImage(planId: string, options?: any) {
        return ConfigurationApiFp(this.configuration).updateFloorplanImage(planId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update zone configuration declaratively. This operation will create, update and delete resources as necessary. 
     * @param {string} zoneId unique zone id
     * @param {ZoneConfig} config zone configuration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConfigurationApi
     */
    public updateZone(zoneId: string, config: ZoneConfig, options?: any) {
        return ConfigurationApiFp(this.configuration).updateZone(zoneId, config, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * query avalible cameras
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cameras: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/cameras`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * query avalible cameras
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cameras(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Camera>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cameras(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * query avalible cameras
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cameras(options?: any): AxiosPromise<Array<Camera>> {
            return localVarFp.cameras(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * query avalible cameras
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public cameras(options?: any) {
        return DefaultApiFp(this.configuration).cameras(options).then((request) => request(this.axios, this.basePath));
    }
}


