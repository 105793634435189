import OnIqLogo from "../assets/on-iqlogo.svg";
import { Link } from "react-router-dom";
import Options from "./Options";
import Clock from "./Clock";

const Navbar = () => {
  return (
    <nav className="nav-bar">
      <Link className="link" to="/">
        <img className="on-iq-logo" src={OnIqLogo} alt="logo" />
      {/* <Link className="link" to="/floorplan">Floorplan</Link> */}
      </Link>
      {/* <Clock /> */}
      <Options />
    </nav>
  );
}

export default Navbar;
